exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-images-furniture-js": () => import("./../../../src/pages/images/furniture.js" /* webpackChunkName: "component---src-pages-images-furniture-js" */),
  "component---src-pages-images-js": () => import("./../../../src/pages/images.js" /* webpackChunkName: "component---src-pages-images-js" */),
  "component---src-pages-images-other-js": () => import("./../../../src/pages/images/other.js" /* webpackChunkName: "component---src-pages-images-other-js" */),
  "component---src-pages-images-stairs-js": () => import("./../../../src/pages/images/stairs.js" /* webpackChunkName: "component---src-pages-images-stairs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

