module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Arkinet OÜ","short_name":"Arkinet OÜ","start_url":"/","background_color":"#7ACA3E","display":"minimal-ui","icon":"src/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f7fbe23e7bf795e342d33b2ea5d4965c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["et","en"],"defaultLanguage":"et","trailingSlash":"never","i18nextOptions":{"interpolation":{"escapeValue":false},"nsSeparator":true}},
    }]
